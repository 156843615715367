<template>
    <div>
        <PrimaryVideoHero
            v-if="featuredWork"
            :cover-image-src="featuredWork.cover_image"
            :cover-image-mobile-src="featuredWork.cover_image_mobile || ''"
            :brand="featuredWork.brand"
            :title="featuredWork.title"
            :vimeo-url="featuredWork.video_url"
            :slug="featuredWork.slug"
        />

        <div class="container mt-12 sm:mt-28 lg:mt-32">
            <AnimatingBrandLogos
                v-if="
                    !$store.state.globals.isLoadingScreenVisible ||
                    !$store.state.globals.shouldPlayLoadingScreenAnimation
                "
            />

            <div class="grid grid-cols-12 gap-5 mt-12 sm:mt-28 lg:mt-32 3xl:mt-40">
                <div class="col-span-12 lg:col-span-6">
                    <div class="header-2 text-left">
                        Hi there, welcome to GYÁR! <br />
                        <h1 class="header-2 text-left">
                            We’re a flexible & affordable 360° (Post) Production House — serving film studios & creative
                            agencies globally, since 2005.
                        </h1>
                    </div>
                </div>

                <div class="col-span-12 lg:col-span-5 lg:col-start-8 pt-8 sm:pt-0">
                    <p class="text-body text-left">
                        GYÁR
                        <ReadButton
                            label="Pronounce Gyár"
                            audio-src="/audio/gyar_long.mp3"
                            class="translate-y-[-1px] lg:-translate-y-1"
                        />
                        is an international artist-driven digital media company, built around an exceptional team of
                        <strong>50+ in-house filmmaking, post-production & VFX/CGI professionals</strong>.
                        <br />
                        <br />
                        During the past two decades, we have built a solid reputation for delivering
                        <strong>
                            top-notch VFX, CGI, Animation, Digital Content, Immersive Experience, Sound works, even
                            end-to-end Film Productions
                        </strong>
                        for Commercials, Feature Films, Television Programs, Games & Event Shows — all the way from
                        <strong>NYC</strong>
                        through
                        <strong>Buenos Aires, London, Beirut & Dubai, back to our beloved home, Budapest.</strong>
                        <br />
                        <br />

                        <Button
                            to="/about"
                            class="p-button-text"
                            icon="pi pi-arrow-right"
                            label="More about GYÁR"
                            aria-label="About GYÁR"
                        />
                    </p>
                </div>
            </div>
        </div>

        <section v-if="featuredWorks.length" class="mt-20 lg:mt-32 xl:mt-44">
            <div class="container">
                <Title
                    text="Reels & selected works"
                    tag="h2"
                    with-dot
                    dot-class="bg-blue-400"
                    class="text-blue-400 mb-12 lg:mb-20"
                />
            </div>

            <ReferenceCard
                v-for="(work, i) in featuredWorks"
                :key="`index-reference-${i}`"
                :brand="work.brand"
                :title="work.title"
                :slug="work.slug"
                :vimeo-url="work.video_url"
                :cover-image-src="work.cover_image"
                :cover-image-mobile-src="work.cover_image"
            />

            <div class="text-center mt-12 lg:mt-24">
                <Button
                    to="/selected-works"
                    class="p-button-icon-only"
                    icon="pi pi-plus"
                    aria-label="Reels & Selected Works"
                />
            </div>
        </section>
    </div>
</template>

<script>
import PrimaryVideoHero from '../components/Hero/PrimaryVideoHero.vue';
import ReferenceCard from '../components/Cards/ReferenceCard.vue';
import AnimatingBrandLogos from '../components/UI/AnimatingBrandLogos.vue';
import ReadButton from '../components/UI/ReadButton.vue';

export default {
    name: 'Index',
    components: {
        ReadButton,
        ReferenceCard,
        PrimaryVideoHero,
        AnimatingBrandLogos,
    },
    computed: {
        featuredWork() {
            return this.$store.state.data.featuredWork;
        },
        featuredWorks() {
            return this.$store.state.data.featuredWorks;
        },
    },
};
</script>

<style scoped></style>
